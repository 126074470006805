import {Component, Input, Output, OnInit, OnChanges, EventEmitter, SimpleChanges} from '@angular/core';
import {CampoAggiuntivo} from "../models/campo-aggiuntivo";


@Component({
  selector: 'app-dynamic-col-table',
  templateUrl: './DynamicColTable.component.html',
  styleUrls: ['./DynamicColTable.component.scss']
})
export class DynamicColTable implements OnInit {


  @Input() hasSave: boolean = false;
  @Output() onSaveClick = new EventEmitter<any>();

    @Input() rows
    @Output() rowsChange = new EventEmitter<any[]>();
    /*= [
        {id: 286, nominativo: "Anna Romano"},
    {id: 293, nominativo: "Fabio Fabbri"}
    ]*/
    @Input() columns
    @Output() columnsChange= new EventEmitter<any[]>();

    @Input() CampiColonne: {nome: string,  param:string|number}[];

    constructor(
    ){

    }

    ngOnInit(){


    }

    Save(){
      this.onSaveClick?.emit({rows: this.rows, columns: this.columns});
    }


  attivitatooltip(attivita) {
    return `${attivita.nome} \n- ${attivita.managerObject?.fullName} \n- ${attivita.descrizione} \n ${attivita.note}`;
  }

  protected readonly CampoAggiuntivo = CampoAggiuntivo;
}




