import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Task} from "../models/Task";
import {ApiPathsService} from "../../api-paths.service";
import {FileStoreService} from "../../shared/_services/file-store.service";
import {DatePipe} from "@angular/common";
import {environment} from "../../../environments/environment";
import {CampoAggiuntivo, CampoUtility, Sezione} from "../../shared/models/campo-aggiuntivo";
import {ToastrService} from "ngx-toastr";
import { Permission } from 'src/app/Login/_guards/Permission';
import { AuthenticationService } from 'src/app/Login/_services/authentication.service';
import {ApiRestService as RmaApiRestService} from "../../rma/_services/api-rest.service";


@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {
  @Input() IsModal: boolean = true;
  @Input() Item: any ={};
  @Input() ExternalAdd: boolean = false;
  @Output() updated = new EventEmitter<Task>();
  @Input() DynamicFields: {
      type?: string,
      addButton?: boolean,
      restRoute?: string,
      Label?: string,
      Azioni?: any[],
      campi?: any[]
    sezioni?: any[],
    managerLoggato?:any,
    initObj?:any[]
  };
  environment = environment;
  DropdownSources: any = {};
  ResetFields: boolean = false;
  constructor(
    private ngbActiveModal: NgbActiveModal,
    private apiPaths: ApiPathsService,
    private filestore: FileStoreService,
    private datepipe: DatePipe,
    private toastrService : ToastrService,
    public campoUtility: CampoUtility,
    public permission: Permission,
    private authSvc: AuthenticationService,
    private modalService: NgbModal,
    private rmaApiRest: RmaApiRestService
  ) { }

  ngOnInit(): void {
    this.Refresh();
  }
  Refresh(){
    if(this.Item?.id) {
      this.ResetFields = true
      this.apiPaths.ClassicPost(`${this.DynamicFields.restRoute}/get`, {id: this.Item.id}).subscribe(data => {
        if (!data) return;
        this.Item = data?.data ?? data;
        this.Item['ripetizione'] = this.Item['ripetizione'] ?? {nGiorni: null};
        this.Item['categoria'] = this.Item['categorie']?.find(x=>true)?.id;
        this.DynamicFields?.initObj?.forEach(x=>{
          if(x.tipo == 'object')
            this.Item[x.campo] = {};
          else if (x.tipo == 'date')
            this.Item[x.campo] = this.datepipe.transform(new Date(), 'yyyy-MM-ddTHH:mm');
          else 
            this.Item[x.campo] =  [];
        })
        if(this.Item['somministrazione'])
        this.Item['somministrazione']['somministrazioni'] = this.Item['orari']?.length;
        this.Item['alert_inizio_task'] =  this.Item['alert_inizio_task'] ?? 0;
        this.Item['alert_fine_task'] =  this.Item['alert_fine_task'] ?? 0;

        if(this.Item['clientiObject']?.length > 0 && this.Item.type == "E" && this.Item.tipo == "LA")
          this.Item['clientiObject']?.forEach(
            cliente =>{
              if(this.Item.figli?.length > 0)
                this.Item.figli.forEach(
                  colonna =>{
                    if(colonna.esiti.findIndex(x => x.cliente == cliente.id) < 0)
                      colonna.esiti.push({cliente:cliente.id, esiti:[0,0,0]})
                  }
                )
            }
          )

        this.FormBuilder();
        this.filestore.SetListaFile(this.Item.files);
        this.filestore.SelectSection(this.DynamicFields.restRoute);
        setTimeout(() => {
          this.ResetFields = false;
        }, 100)
      })
    }
    else{
      if(!this.Item) this.Item = {};

      this.Item['ripetizione'] = {nGiorni: null};

      this.DynamicFields?.initObj?.forEach(x=>{
        if(x.tipo == 'object')
          this.Item[x.campo] = {};
        else if (x.tipo == 'date')
          this.Item[x.campo] = this.datepipe.transform(new Date(), 'yyyy-MM-ddTHH:mm');
        else 
          this.Item[x.campo] =  [];
      })
      this.Item['alert_inizio_task'] =  this.Item['alert_inizio_task'] ?? 0;
      this.Item['alert_fine_task'] =  this.Item['alert_fine_task'] ?? 0;
      this.Item['ora_inizio'] =  this.Item['ora_inizio'] ?? '00:00';
      this.Item['ora_fine'] =  this.Item['ora_fine'] ?? '23:59';
      this.Item.data_inizio_prevista = this.datepipe.transform(new Date(), 'yyyy-MM-ddTHH:mm')
      this.setManager();
    }
  }
  setManager(){
    if(this.campoUtility.Show({hideif:this.DynamicFields.managerLoggato, modificabile:true}, this.Item, true))
    this.Item.managerObject= this.Item.managerObject ?? this.authSvc.currentUserValue;
  }

  FormBuilder(){
    this.DynamicFields?.sezioni?.forEach(sezione=>{
      sezione.campi?.forEach(campo=>{
        this.SetCampoSource(this.Item, campo);
      })
    })
    this.DynamicFields?.campi?.forEach(campo=>{
       this.SetCampoSource(this.Item, campo);
    })
    this.setManager();

  }

  SetCampoSource(item, campo){
    if((campo['type'] == 'checkboxArray' || campo['type'] == 'checkboxArrayMEXC') && (!campo['filter'] || item?.sedeObject?.clienteObject?.id)) {
      console.log(item[campo['oggetti'][0]]);
      this.rmaApiRest.GetCampiAggiuntiviRmas(item[campo['oggetti'][0]]?.filter(x => typeof x == 'number' ? true : x.risposta) ?? [], campo['oggetti'][0], campo['filter'] ? {id: item?.sedeObject?.clienteObject?.id} : null).subscribe(data => {
        item[campo['oggetti'][0]] = data;
      });
    }
    if(campo['source'] == 'PrioritaSource')
      this.apiPaths.ClassicGet('/rma/rma/priorita').subscribe(data => this.DropdownSources.PrioritaSource = data ?? []);

    if(campo['source'] == 'PeriodiSource'){
      this.apiPaths.ClassicGet('/rma/rma/periodi').subscribe(data=>{
        this.DropdownSources.PeriodiSource = data;
      })
    }
  }
  close(){
    this.ngbActiveModal.dismiss();
  }
  save(close?: boolean) {
    if(!this.ValidateError(this.Item)) return;
    this.Item.partecipanti = this.Item?.partecipantiObject?.map(x=>x.id)
    this.Item.categorie = this.Item?.categoria?[this.Item?.categoria] : [];
    if(this.Item['clientiObject'] && this.Item.type == "N")
      this.Item['clienti'] = this.Item['clientiObject'].map(x=> {return {id: x.id, letto: false}});
    else if (this.Item['clientiObject'])
      this.Item['clienti'] = this.Item['clientiObject'].map(x=> {return  x.id});
    this.Item.periodo = this.Item['ripetizione']?.repeatEvery;
    this.Item.tipo_periodo = this.Item['ripetizione']?.frequency;
    this.Item.giorno_mese_ordine = this.Item['ripetizione']?.ordine;
    //this.Item.giorno_mese_giorno = this.Item['ripetizione']?.daysOfWeek; !!è un array
    this.Item.frequenza = this.Item['ripetizione']?.occurrences;

    if (this.ExternalAdd){
      this.updated.emit(this.Item);
      this.close();
      return;
    }
    if (this.Item.id)
      this.apiPaths.ClassicPost(`${this.DynamicFields.restRoute}/update`,this.Item).subscribe((data) =>this.saved(this.Item, close));
    else
      this.apiPaths.ClassicPost(`${this.DynamicFields.restRoute}/add`,this.Item).subscribe((data) => this.saved(data, close));

    if(this.Item.type == 'E' && this.Item?.tipo == 'LA' && this.Item.figli?.length >0 && this.Item.clientiObject){
      this.UpdateAttivitas();
    }
  }
  saved(data, close?:boolean){
     //console.log("Error", this.ValidateError(data))
    this.Item.id = data.id;
    this.updated.emit(this.Item);
    this.Refresh();
    if (close)
      this.close();
  }
  onFileChanged() {
    this.Refresh();
  }

  ChangeHandler(item: { OnChange: string; Event: any, CampoAggiuntivo?: CampoAggiuntivo }) {
    console.log("Changehandler: ", item);
    switch (item.OnChange ) {
      case 'OnFilesChange' :
        this.Refresh();
        break;
      case 'AddCatCom' :
        if(!this.Item['clientiObject'])  this.Item['clientiObject'] = [];
        if(this.Item['addCatCom'])  {
          this.apiPaths.ClassicPost('/anagrafica/clienti/list',{page: 0, size: 10}).subscribe(data=>{
            var clientiCatCom = data?.data?.filter(x=>x.tipiObject?.find(y=>y.id == this.Item['addCatCom']?.id))
            console.log(`Clienti di categoria ${this.Item['addCatCom']?.nome}`,clientiCatCom);
            console.log(`Task: `, this.Item);
            this.Item['clientiObject'] = [...this.Item['clientiObject'],...clientiCatCom];
            setTimeout(()=>{this.Item['addCatCom'] = null;}, 300);
          })

        }
        break;
      case 'OnDelete':
        this.Item['clientiObject'] = this.Item['clientiObject'].filter(x => x.id != item.Event.id);
        break;
      case 'somministrazionichange':
        console.log('Somministrazione Change', this.Item)
        if (!this.Item['somministrazione']['somministrazioni'])break;
        this.Item['orari'] = this.Item['orari'] ?? [];
        const attualeNumSomministrazioni = this.Item['orari']?.length ?? 0;

        if (this.Item['somministrazione']['somministrazioni'] > attualeNumSomministrazioni) {
          for (let i = 0; i < this.Item['somministrazione']['somministrazioni'] - attualeNumSomministrazioni; i++) {
            this.Item['orari'].push({});
          }
        } else if (this.Item['somministrazione']['somministrazioni'] < attualeNumSomministrazioni) {
          for (let i = 0; i < attualeNumSomministrazioni - this.Item['somministrazione']['somministrazioni']; i++) {
            this.Item['orari'].pop();
          }
        }
        break;
      case 'OnTableEdit':
        console.log('Item ChangeHandler', item)
        var dynamicFields = this.campoUtility.getDynamicTaskSctructure(item?.CampoAggiuntivo?.tasktype ?? item?.Event?.type, item?.CampoAggiuntivo["taskfilter"]);
        //var dynamicFields = this.environment.TaskAddon['DynamicFields']?.find(x => x.type.toLocaleUpperCase() == (item?.CampoAggiuntivo?.tasktype ?? item?.Event?.type)?.toLocaleUpperCase());
        if(!dynamicFields) return;
        let Newsmodal = this.modalService.open(NewsComponent, {
          centered: true,
          backdrop: 'static',
          size: 'xl',
        });
        (<NewsComponent>Newsmodal.componentInstance).DynamicFields = dynamicFields;
        (<NewsComponent>Newsmodal.componentInstance).IsModal = true;
        (<NewsComponent>Newsmodal.componentInstance).Item = item.Event ?? new Task();
        (<NewsComponent>Newsmodal.componentInstance).updated.subscribe(data => {
          this.Refresh()
        });
        break;

      case 'OnDeleteObiettivo':

        break;

    }
  }
  getparam(oggetto, param){
    return oggetto ? oggetto[param]: null;
  }
  customButton(selected, button: { type?: 'GET'| 'POST'| 'GOTO'|'addTask'|'addTaskRicorrente'|'esegui', restRoute?: string, preMex?: string, successMex?: string, parameter?: any[], preAlert: string, close?:boolean, taskType?:string, taskFilter?:string}) {
    if(this.Item['clientiObject'])
      this.Item['clienti'] = this.Item['clientiObject'].map(x=>x.id);
    if(selected && (!button?.preAlert || confirm(button.preAlert))){

      var filter ={};
      button.parameter?.forEach(param=>{
        var p = param?.parameter?.split('.');
        var parameter = Object.assign({}, selected)
        p?.forEach(el=>{
          parameter = this.getparam(parameter,el);
        })
        filter[param.nome] = param.value ?? parameter
      })
      if(button?.restRoute?.includes(':id') )
        button.restRoute = button.restRoute.replace(':id', this.Item.id)
      if(button.restRoute?.includes(':token') )
        button.restRoute = button.restRoute.replace(':token', this.authSvc.currentUserValue?.access_token )
      if(button.preMex)
        this.toastrService.warning(button.preMex);
      if(button.type == 'GET' && button.restRoute)
        this.apiPaths.ClassicGet(button.restRoute).subscribe(data=>{
          if(button.successMex)
            this.toastrService.success(button.successMex);
          if(button.close)
            this.close();
          this.updated.emit(data);
          this.Refresh();

        })
      if(button.type == 'POST' && button.restRoute)
        this.apiPaths.ClassicPost(button.restRoute,filter).subscribe(data=>{
          if(button.successMex)
            this.toastrService.success(button.successMex);
          if(button.close)
            this.close();
          else(
            this.Refresh()
          )
          this.updated.emit(data);


        })
      if(button.type == 'GOTO' && button.restRoute) {

        window.open(button.restRoute, '_blank');
        if(button.successMex)
          this.toastrService.success(button.successMex);
        if(button.close)
          this.close();
      }
      if(button?.type == 'addTask' && button?.taskType) {
        this.addDynamicButton(this.campoUtility.getDynamicTaskSctructure(button.taskType, button.taskFilter))

        if(button.successMex)
          this.toastrService.success(button.successMex);
        if(button.close)
          this.close();
      }
      if (button.type == 'addTaskRicorrente' && button?.taskType){
        var cliente = this.Item['clientiObject'] ? this.Item['clientiObject'].map(x=>x.id) : undefined;
        var tasktype = this.campoUtility.getDynamicTaskSctructure(button.taskType, button.taskFilter);
        var newtask = {
          cliente: cliente ?? this.Item.cliente,
          clienteObject: this.Item.clienteObject,
          valutazione: this.Item.type == "V" ? this.Item.id : null,
          padre: (this.isTaskType(['O','R', ]) || tasktype.type == 'Y')   ? this.Item.id : null,
          tipo: button.taskFilter,
          nome: this.Item.nome,
          alert_inizio_task: 0,
          alert_fine_task: 0,
        };
        if(!this.Item.data_fine_prevista){
          this.toastrService.error( "Indicare la data di fine per utilizzare questa funzionalità", "Errore");
          return;
        }
        if(!tasktype?.campi?.some(x=>this.campoUtility.Show(x, newtask,true)))
          this.AddPeriodicTask(newtask, tasktype);
        else {
          const editmodal = this.modalService.open(NewsComponent, {
            centered: true,
            backdrop: 'static',
            size: 'xl',
          });
          (<NewsComponent>editmodal.componentInstance).DynamicFields = tasktype;
          (<NewsComponent>editmodal.componentInstance).ExternalAdd = true;
          (<NewsComponent>editmodal.componentInstance).IsModal = true;
          (<NewsComponent>editmodal.componentInstance).Item = newtask;
          (<NewsComponent>editmodal.componentInstance).updated.subscribe(data => {
            this.AddPeriodicTask(data,tasktype)
          });
        }
      }
      if (button.type == 'esegui' && button?.taskType){
        this.Item.data_inizio_effettiva = this.datepipe.transform(new Date(), 'yyyy-MM-ddTHH:mm')
        this.save();
      }
    }
  }

  AddPeriodicTask(data: any, tasktype:any){
    if(!this.Item.data_fine_prevista){
      this.toastrService.error( "Indicare la data di fine per utilizzare questa funzionalità", "Errore");
      return;
    }
    var dates = this.Item?.ripetizione?.nGiorni ? this.generateDateArrayEveryXDays(
      this.Item.data_inizio_prevista,
      parseInt(this.Item?.ripetizione?.nGiorni.toString()),
      this.Item.data_fine_prevista,
    ) : this.generateDateArray(
      this.Item.data_inizio_prevista ?? this.Item['data_attivita'],
      this.Item.data_fine_prevista,
      this.Item.ripetizione?.repeatEvery,
      this.Item.ripetizione?.frequency,
      this.Item.ripetizione?.daysOfWeek,
      this.Item.ripetizione?.endCondition,
      this.Item.ripetizione?.endDate,
      this.Item.ripetizione?.occurrences
    );
    dates.forEach(date =>{
      var newtask = Object.assign({},data);
      newtask.data_inizio_prevista = `${date}T00:00`;
      newtask.data_attivita = `${date}T00:00`;
      newtask.data_fine_prevista = `${date}T23:59`;
      this.apiPaths.ClassicPost(`${tasktype.restRoute}/add`,newtask).subscribe((data) => this.saved(this.Item, false));
    })
  }

  isTaskType(tipi: string[]){
    return tipi?.includes(this.Item.type);
  }
  addDynamicButton(dynamicField) {
    var cliente ;
    if(this.Item['clientiObject'])
      cliente = this.Item['clientiObject'].map(x=>x.id);
    const editmodal = this.modalService.open(NewsComponent, {
      centered: true,
      backdrop: 'static',
      size: 'xl',
    });
    (<NewsComponent>editmodal.componentInstance).DynamicFields = dynamicField;
    (<NewsComponent>editmodal.componentInstance).IsModal = true;
    (<NewsComponent>editmodal.componentInstance).Item = {
      cliente:  this.Item.cliente,
      clienti: cliente,
      clienteObject: this.Item.clienteObject,
      valutazione: this.Item.type == "V" ? this.Item.id : null,
      padre: ((this.Item.type == "O" && dynamicField.type == 'G') || this.Item.type == "E" || (dynamicField.type == "Y"))  ? this.Item.id : null,
      rma: this.Item.type == "R" ? this.Item.id : null,
      obiettivo: this.Item.type == "O" ? this.Item.id : null,
      nome: this.Item.type == "E"  ? this.Item.nome : "",
      tipo: dynamicField.tipo,
    };
    (<NewsComponent>editmodal.componentInstance).updated.subscribe(data => {
      this.Refresh();
    });
  }
  FilterHandler($event: {OnFilter: string; Event: any}) {

  }
  generateDateArray(startDate: string, endDate: string, repeatEvery: number, frequency: string, daysOfWeek: any, endCondition: string, endDateCondition: string, occurrences: number): string[] {
    var exError = this.validateRipetizione(startDate,endDate,repeatEvery,frequency,daysOfWeek,endCondition,endDateCondition,occurrences)
    if(exError){
      this.toastrService.error(exError,'Errore')
      return [];
    }

      const result: string[] = [];
      let currentDate = new Date(startDate);
      const finalDate = endCondition === 'date' ? new Date(endDateCondition!) : new Date(endDate);
      let count = 0;
      // Helper function to format date as yyyy-MM-dd
      const formatDate = (date: Date): string => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
      };

      while (currentDate <= finalDate && (endCondition !== 'occurrences' || count < occurrences!)) {
        const dayOfWeek = currentDate.getDay();
        const dayMap = {
          0: 'sunday',
          1: 'monday',
          2: 'tuesday',
          3: 'wednesday',
          4: 'thursday',
          5: 'friday',
          6: 'saturday'
        };

        if (frequency !== 'weeks' || daysOfWeek[dayMap[dayOfWeek]]) {
          result.push(formatDate(currentDate));
          count++;
        }

        switch (frequency) {
          case 'days':
            currentDate.setDate(currentDate.getDate() + repeatEvery);
            break;
          case 'weeks':
            currentDate.setDate(currentDate.getDate() + repeatEvery * 7);
            break;
          case 'months':
            currentDate.setMonth(currentDate.getMonth() + repeatEvery);
            break;
          case 'years':
            currentDate.setFullYear(currentDate.getFullYear() + repeatEvery);
            break;
          default:
            throw new Error(`Unsupported frequency: ${frequency}`);
        }
      }

      return result;

  }
  generateDateArrayEveryXDays(startDate: string, intervalDays: number, endDate?: string): string[] {
    const result: string[] = [];
    let currentDate = new Date(startDate);
    const finalDate = new Date(endDate);

    if (isNaN(currentDate.getTime()) || isNaN(finalDate.getTime())) {
      console.error('Date non valide:', startDate, endDate);
      return result;
    }

    if (intervalDays <= 0) {
      console.error('intervalDays deve essere un numero positivo:', intervalDays);
      return result;
    }

    // Helper function to format date as yyyy-MM-dd
    const formatDate = (date: Date): string => {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    // Loop per generare le date
    while (currentDate <= finalDate) {
      console.log('Aggiungendo data:', formatDate(currentDate));
      result.push(formatDate(currentDate));
      var dummy = new Date()
       dummy.setDate(currentDate.getDate() + intervalDays);
      currentDate = dummy;
    }

    console.log('Date da aggiungere', result);
    return result;
  }
  validateRipetizione(
    startDate: string,
    endDate: string,
    repeatEvery: number,
    frequency: string,
    daysOfWeek: any,
    endCondition: string,
    endDateCondition?: string,
    occurrences?: number
  ): string | null {
    const validFrequencies = ['days', 'weeks', 'months', 'years'];
    const validEndConditions = ['never', 'date', 'occurrences'];

    const isValidDate = (dateStr: string): boolean => !isNaN(new Date(dateStr).getTime());

    if (!isValidDate(startDate)) {
      return 'La data di inizio non è valida.';
    }

    if (endCondition === 'date' && (!endDateCondition || !isValidDate(endDateCondition))) {
      return 'La data di fine non è valida.';
    }

    if (endCondition === 'date' && new Date(endDateCondition!) <= new Date(startDate)) {
      return 'La data di fine deve essere successiva alla data di inizio.';
    }

    if (repeatEvery <= 0) {
      return 'L\'intervallo di ripetizione deve essere un numero positivo maggiore di zero.';
    }

    if (!validFrequencies.includes(frequency)) {
      return 'La frequenza non è valida.';
    }

    if (!validEndConditions.includes(endCondition)) {
      return 'La condizione di fine non è valida.';
    }

    if (endCondition === 'occurrences' && (!occurrences || occurrences <= 0)) {
      return 'Il numero di occorrenze deve essere un numero positivo maggiore di zero.';
    }

    if (frequency === 'weeks' && !Object.values(daysOfWeek).some(day => day)) {
      return 'Deve essere selezionato almeno un giorno della settimana per la frequenza settimanale.';
    }

    return null;
  }

  Validate(sezioni, oggetto){
    var campiNonValidati = [];
    campiNonValidati = [...campiNonValidati, ...this.ValidateCampi(sezioni, oggetto)]//})
    return campiNonValidati;
  }
  ValidateError(oggetto){
    if(this.DynamicFields.campi){
      console.log("campiNonValidati", this.Validate(this.DynamicFields.campi, oggetto));
      var campiNonValidati = this.Validate(this.DynamicFields.campi, oggetto);
      if(campiNonValidati?.length > 0){
        var errore = "";
        campiNonValidati.forEach(x=>{
          errore+=`Il campo ${x} è richiesto;\n`
        })
        this.toastrService.error(`${errore}`,`Inserire i campi richiesti`);
        return false;
      }
      return true;
    }
    return true;
  }
  ValidateCampi(campi: any[], oggetto){
    return campi?.filter(campo => this.campoUtility.Required(campo,oggetto) && !this.is_Valid(campo,oggetto)).map(x=>x.nome);
    //return ["ciao"];
  }
  is_Valid(campo,oggetto){
    return !this.campoUtility?.getValues(campo,oggetto)?.some(x=> !x || (Array.isArray(x) && !(x.length > 0)));
  }

  UpdateAttivitas() {
    this.Item?.figli?.forEach(figlio=>{
      this.apiPaths.ClassicPost(`${this.campoUtility.getDynamicTaskSctructure(figlio.type,  figlio?.tipo ?? null)?.restRoute}/update`,figlio).subscribe()
    })
  }
}
